<template>
  <div class="main-con">
    <div class="content">
      <div class="top">
        <span>喜加一({{ filteredGameLists.length }})</span>
      </div>
      <div class="bottombg">
        <div class="bottom">
          <div class="scrollable-container">
            <div class="item" v-for="(item, index) in filteredGameLists" :key="index">
              <div class="left">
                <img :src="item.pictureUrl" alt="Don't Shout Together">
              </div>
              <div class="right">
                <p class="name">{{ item.gameName }}</p>
                <div class="time">
                  <span>{{ filterTime(item.computedResult) }}</span>
                </div>
                <p class="intro">
                  {{ item.gameDesc }}
                </p>
                <div class="btn" @click="openExternalLink(item.getUrl)">
                  <span>领取</span>
                </div>
              </div>
            </div>
            <div class="item"></div>
            <div class="item"></div>
            <div class="item"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doGet } from '../http/httpRequest.js'

export default {
  name: 'GameXJYContentView',

  data() {
    return {
      game_lists: [],
      intervalId: null
    };
  },
  mounted() {
    // let endTime = 1719388799000;
    // console.log("当前时间:", this.formatTimeInEast8(Date.now()));
    // console.log("截止时间:", this.formatTimeInEast8(endTime));
    // console.log("filterTime(endTime)", this.filterTime(endTime));

    doGet("/api/game/list", {})
      .then(response => {
        console.log(response.data.data);
        this.game_lists = response.data.data;

        // Set up interval to update remaining time every second
        this.intervalId = setInterval(() => {
          this.$forceUpdate(); // Force Vue to re-render to update the displayed time
        }, 100); // Update every 100 milliseconds for a smoother display
      })
      .catch(error => {
        console.error('Error:', error);
      });
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.intervalId);
  },
  computed: {
    filteredGameLists() {
      return this.game_lists;
    }
  },
  methods: {
    openExternalLink(link) {
      window.open(link, '_blank');
    },
    isOverdue(currentTime, endTime) {
      return currentTime > endTime;
    },
    filterTime(endTime) {
      // Ensure endTime is in milliseconds
      if (endTime < 10000000000) {
        endTime = endTime * 1000;
      }

      let now = Date.now();
      let remainingTime = endTime - now;

      if (remainingTime <= 0) {
        return "已错过免费领取时间";
      }

      // Calculate days, hours, minutes, seconds, and milliseconds
      const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
      const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
      const milliseconds = remainingTime % 1000;

      return `免费领取剩余: ${days}天${hours}时${minutes}分${seconds}秒`;
    },
    formatTimeInEast8(timeInMillis) {
      return new Date(timeInMillis).toLocaleString("zh-CN", { timeZone: "Asia/Shanghai" });
    }
  }

};
</script>

<style scoped>
@import '../assets/css/common.css';
@import '../assets/css/variable.css';

.main-con {
  width: 100vw;
  height: calc(100vh - 2.9167vw);
  background: rgb(255 241 241 / 52%);
  backdrop-filter: blur(.3646vw);
  font-family: 'LatoR';
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-con .content {
  width: 48.6458vw;
  height: 76vh;
  padding: .5729vw 15.9896vw;
  background: #fff;
  border-radius: 1.9271vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: .8333vw 1.25vw;
  overflow: hidden;
  padding-bottom: 5vh;
}

.content .top {
  width: 100%;
  padding: .8333vw 0 .8333vw 2.0833vw;
  border-bottom: .1021vw solid #EEEEEE;
}

.top span {
  font-size: 1.25vw;
  color: #3D3D3D;
}

.content .bottombg {
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.bottombg .bottom {
  width: 100%;
  height: 100%;
  padding-top: .5208vw;
  padding-left: .5208vw;
}

.bottom .item {
  width: 40.8333vw;
  height: 12.3958vw;
  display: flex;
  flex-direction: row;
  gap: 1.875vw;
  padding: 1.25vw 0;
  padding-left: 2.2917vw;
}

.scrollable-container {
  max-height: 60vw !important;
  overflow-y: auto;
}

.item .left {
  width: 22.0313vw;
  height: 100%;
}

.left img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.item .right {
  width: 18.75vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: .625vw;
  border-bottom: .0521vw solid #efefef;
}

.right p.name {
  font-size: 1.0417vw;
  font-family: 'LatoB';
}

.right .time {
  font-size: .7292vw;
  color: #fff;
}

.time span {
  display: inline-block;
  background: #0078F2;
  border-radius: .2521vw;
  padding: .0521vw .0521vw;
}

.right .intro {
  font-size: .7292vw;
  color: #ADB5BD;
  width: 17.3438vw;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
}

.right .btn {
  width: 7.1979vw;
  height: 1.4063vw;
  background: #00B15B;
  color: #fff;
  font-size: .7292vw;
  text-align: center;
  line-height: 1.4063vw;
  border-radius: 1.25vw;
  cursor: pointer;
  transition: box-shadow .2s ease-in-out;
}

.right .btn:hover {
  box-shadow: 0vw .1563vw .1563vw 0vw rgba(0, 0, 0, 0.3);
}
</style>
