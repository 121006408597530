<template>
    <div class="custom-table-container">
        <table class="custom-table">
            <thead>
                <tr>
                    <th @click="toggleSortDirection('id')">ID</th>
                    <th @click="toggleSortDirection('title')">GameName</th>
                    <th @click="toggleSortDirection('alt')">EndTime</th>
                    <th @click="toggleSortDirection('ico')">PictureUrl</th>
                    <th @click="toggleSortDirection('icoID')">getUrl</th>
                    <th @click="toggleSortDirection('link')">gameDesc</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in paginatedData" :key="item.id">
                    <td>{{ item.id }}</td>
                    <td>{{ item.gameName }}</td>
                    <td>{{ item.endTimeFormat }}</td>
                    <td>{{ item.pictureUrl }}</td>
                    <td>{{ item.getUrl }}</td>
                    <td>{{ item.gameDesc }}</td>
                    <td>
                        <div class="btn">
                            <button class="custom-button" @click="handleEdit(index, item)">编辑</button>
                            <button class="custom-button danger" @click="handleDelete(index, item)">删除</button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="footer">
            <div class="pagination-controls" v-if="false">
                <label for="pageSize">Rows per page:</label>
                <select id="pageSize" v-model.number="pageSize" @change="handlePageSizeChange">
                    <option v-for="size in pageSizes" :key="size" :value="size">{{ size }}</option>
                </select>
            </div>
            <div class="pagination-controls">
                <button class="custom-button" @click="prevPage" :disabled="currentPage === 1">上一页</button>
                <span>Page {{ currentPage }} of {{ totalPages }}</span>
                <button class="custom-button" @click="nextPage" :disabled="currentPage === totalPages">下一页</button>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "TableXjyComponent",
    props: {
        tableXjyDataList: Array,
    },
    mounted(){
        this.tableDataList = this.tableXjyDataList
    },
    watch: {
        tableXjyDataList(newVal, oldVal) {
            this.tableDataList = newVal;
            // 在这里可以对新值进行处理
        },
    },
    data() {
        return {
            tableDataList: this.tableXjyDataList,
            currentPage: 1,
            pageSize: 10,
            pageSizes: [5, 10, 15, 20],
            xjyEditForm: {
                id: '',
                gameName: '',
                beginTime: '',
                endTime: '',
                getUrl: '',
                pictureUrl: '',
                gameDesc: '',
                isChinese: ''
            },
            editIndex: -1,
            sortDirection: 'asc' // 初始排序方向为升序
        };
    },
    computed: {
        tableData() {
            return this.tableDataList;
        },
        paginatedData() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = start + this.pageSize;

            return this.tableData.slice(start, end);

        },
        totalPages() {
            return Math.ceil(this.tableData.length / this.pageSize);
        }
    },

    methods: {
        handleEdit(index, item) {
            this.editForm = { ...item };
            this.editIndex = index;
            this.dialogVisible = true;

            // 传给父组件
            console.log("editForm", this.editForm);
            this.$emit('dialogVisible', true);
            this.$emit('editForm', this.editForm);
        },
        handleDelete(index, item) {
            console.log("handleDelete-item", item.id);
            this.$emit('delete', item.id);
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        handlePageSizeChange() {
            this.currentPage = 1; // Reset to the first page whenever page size changes
        },
        toggleSortDirection(key) {
            this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'; // 切换排序方向
            this.sortData(key); // 调用排序方法，并传递键值
        },
        sortData(key) {
            // 使用动态键排序
            console.log("this.tableDataList", this.tableDataList);
            this.tableDataList = this.tableDataList.sort((a, b) => {
                if (this.sortDirection === 'asc') {
                    return a[key] > b[key] ? 1 : -1;
                } else {
                    return a[key] < b[key] ? 1 : -1;
                }
            });
        },
    },

};

</script>
  
<style scoped>
@import '../assets/css/common.css';
@import '../assets/css/variable.css';


.custom-table-container {
    width: 65.5208vw;
    padding: calc(1.25vw) calc(1.25vw);
    font-family: 'LatoR';
    display: flex;
    flex-direction: column;
    gap: .5729vw;
    background: #fff;
}

.custom-table {
    width: 100%;
    table-layout: fixed;
}

.custom-table th,
.custom-table td {
    width: 9.375vw;
    height: 2.0833vw;
    background: #fff;
    line-height: 2.0833vw;
    padding: 0 .625vw;
    font-size: .7292vw;
    color: #909399;
    font-family: 'SYHT-R';
    white-space: nowrap;
    /* 禁止换行 */
    overflow: hidden;
    /* 超出部分隐藏 */
    text-overflow: ellipsis;
    /* 用省略号表示被隐藏的文本 */
    border-bottom: .0521vw solid rgba(128, 128, 128, 0.2);
}

.custom-table th {
    text-align: left;
}

.custom-table td {
    height: 1.8333vw !important;
}

.custom-table th:nth-last-of-type(1) {
    width: 6.25vw;
    border-left: .0521vw solid rgba(128, 128, 128, 0.2);
    text-align: center;
}


.custom-table td:nth-last-of-type(1) {
    width: 6.25vw;
    height: 2.2917vw !important;
    border-left: .0521vw solid rgba(128, 128, 128, 0.2);
    padding: .625vw 1.4583vw;
}

.custom-table td:nth-last-of-type(1) .btn {
    width: 100%;
    height: 100%;
    display: flex;
    gap: .8333vw;
}

.btn button {
    font-weight: bolder;
    color: #B6504B;
    background: #fff;
    border: none;
    cursor: pointer;
}

.custom-table-container .footer {
    width: calc(65.5208vw - 2.5vw);
    height: 2.6563vw;
    padding: .625vw;
}

.footer .pagination-controls {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: .4167vw;
    justify-items: center;
    align-items: center;
    justify-content: center;
}

.footer .pagination-controls button {
    font-size: .625vw;
    font-family: 'SYHT-R';
    font-weight: bolder;
    color: #B6504B;
    cursor: pointer;
    border: none;
    background: #fff;
    transition: all .3s ease-in-out;
    padding: .2083vw;
}

.footer .pagination-controls button:hover {
    color: #fff;
    background: #B6504B;
}


/*  */
</style>
  