<template>
  <div class="main-con">
    <div class="content">
      敬请期待
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmailContentView',

  data() {
    return {

    };
  },

  mounted() {

  },

  methods: {

  },
};
</script>

<style scoped>
@import '../assets/css/common.css';
@import '../assets/css/variable.css';

.main-con {
  width: 100vw;
  height: calc(100vh - 2.9167vw);
  background: #212121;
  backdrop-filter: blur(.3646vw);
  font-family: 'LatoR';
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-con .content {
  width: 48.6458vw;
  height: 76vh;
  padding: .5729vw 15.9896vw;
  border-radius: 1.9271vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: .8333vw 1.25vw;
  overflow: hidden;
  padding-bottom: 5vh;
  justify-items: center;
  align-items: center;
  font-size: 1.5625vw;
  font-weight: bolder;
  font-family: 'SYHT-B';
  color: #747474;
  user-select: none;
}
</style>