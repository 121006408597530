import axios from "axios";

//定义后端接口地址的前缀
axios.defaults.baseURL = "http://175.178.173.145:8083";
// axios.defaults.baseURL = "http://localhost:8081";

axios.defaults.headers = {
  "X-Device-Type": "2",
  "X-Device-Name": "Browser"
}

export function doGet(url, params) {
  // 发送 GET 请求
  return axios.get(url, {
    params: params,
    dataType: "json"
  });
}

export function doGet_axios(url, params) {
  return axios({
    method: "get",
    url: url,
    params: params,
    dataType: "json"
  }); 
}

export function doPost(url, data) {
  // 发送 POST 请求
  return axios({
    method: "post",
    url: url,
    data: data, //data
    dataType: "json"
  }); 
}

export function doPost_form(url, data) {
  return axios.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

// 将原来的Fetch PUT请求封装成一个exported函数
export function doPut(url, data) {
  // 设置axios PUT请求的默认配置
  const defaultConfig = {
    method: "put",
    url: url,
    headers: {
      "Content-Type": "application/json"
    },
    data: JSON.stringify(data) // 将数据转为JSON字符串
    // 其他配置项
  };

  // 发送 PUT 请求
  return axios(defaultConfig)
    .then(function (response) {
      console.log("发起 put 请求:", url);
      return response.data; // 返回响应数据
    })
    .catch(function (error) {
      console.error("PUT 请求失败:", error);
      throw error; // 抛出错误，可以在调用函数的地方捕获处理
    });
}

// 将原来的axios DELETE请求封装成一个exported函数
export function doDelete(url, config) {
  // 设置axios DELETE请求的默认配置
  const defaultConfig = {
    method: "delete",
    url: url,
    ...config // 允许传入额外的配置
  };

  // 发送 DELETE 请求
  return axios(defaultConfig)
    .then(function (response) {
      console.log("发起 delete 请求:", url);
      return response.data; // 返回响应数据
    })
    .catch(function (error) {
      console.error("DELETE 请求失败:", error);
      throw error; // 抛出错误，可以在调用函数的地方捕获处理
    });
}
