import "lib-flexible";
import "normalize.css";
import { createApp } from "vue";

//引入element-plus
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';

//引入图标
import '@fortawesome/fontawesome-free/css/all.css';

import App from "./App.vue";
import router from "./router";
import store from "./store";

const app = createApp(App);


app.use(store);
app.use(router);
app.use(ElementPlus, {
  locale: zhCn
});

app.mount("#app");
