<template>
  <body>
    <div class="header-con">
      <div class="indexLogo">
        <img src="./assets/images/LOGO.svg" alt="">
      </div>
      <div class="nav">
        <div class="nav-item" v-for="item in navItems" :key="item.id" :class="{ 'active-navItem': isActive(item.id) }"
          @click="navClick(item.id, item.path, item.isFooter)">
          <a href="#">{{ item.title }}</a>
        </div>
      </div>
    </div>
    <!-- //QuickContentView -->
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>

    <!-- //FooterView -->
    <FooterView v-show="isFooterShow" />
  </body>
</template>

<script>
import FooterView from './views/FooterView.vue'
import QuickContentView from './views/QuickContentView.vue'

import { reactive, toRefs, onBeforeMount, onMounted } from 'vue'

import { doGet } from './http/httpRequest.js'


export default {
  name: 'App',
  components: {
    FooterView,
    QuickContentView,
  },
  data() {
    return {
      isFooterShow: true,
      navItems: []
    }
  },
  mounted() {
    //数据初始化
    doGet("/api/nav/list", {})
      .then(response => {
        console.log(response.data.data);
        this.navItems = response.data.data;

        // Set up interval to update remaining time every second
        this.intervalId = setInterval(() => {
          this.$forceUpdate(); // Force Vue to re-render to update the displayed time
        }, 100); // Update every 100 milliseconds for a smoother display
      })
      .catch(error => {
        console.error('Error:', error);
      });
  },
  methods: {
    changeSelect(data) {
      // 使用 mutations 来修改 select 属性
      this.$store.commit('setSelect', data);
    },
    isActive(itemId) {
      return this.$store.state.select === itemId;
    },
    navigateTo(path) {
      // 使用$router.push来进行路由跳转
      this.$router.push(path);
      // 阻止a标签默认的href跳转行为
      return false;
    },
    navClick(index, path, isFooterShow) {
      this.changeSelect(index)
      this.navigateTo(path)
      this.isFooterShow = isFooterShow
    }
  },
  setup() {
    // console.log('1-开始创建组件-setup')
    const data = reactive({})
    onBeforeMount(() => {
      // console.log('2.组件挂载页面之前执行----onBeforeMount')
    })
    onMounted(() => {
    })
    return {
      ...toRefs(data),
    }
  },
  computed: {

  }
}
</script>


<style scoped>
@import './assets/css/common.css';
@import './assets/css/variable.css';



.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

 /* 固定背景图片 */
/* body {
  background: var(--index_bg1);
  background-size: cover;
  background-attachment: fixed;
} */


/* header */
.header-con {
  position: sticky;
  z-index: 999;
  top: 0;
  width: 100vw;
  height: 2.9167vw;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  box-shadow: 0vw .2083vw .5208vw 0vw rgba(0, 0, 0, 0.3);
  padding: 0 3.75vw;
  gap: 10.9375vw;
}

.header-con .indexLogo {
  width: 7.5521vw;
  height: 1.9792vw;
  cursor: pointer;
  /* background: #000; */
  margin: .4688vw 0;
  text-align: center;
}

.indexLogo img {
  height: 100%;
  border-bottom: .1563vw none #94525e;
}


.header-con .nav {
  width: auto;
  height: 100%;
  /* background: green; */
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  gap: 1.25vw;
}


.nav .nav-item {
  width: 7.1875vw;
  height: 2.9167vw;
  /* background: pink; */
  text-align: center;
  cursor: pointer;
  font-family: 'LatoR';
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  border-bottom: .2304vw solid #67514e00;
  transition: all .4s ease-in-out;
  box-sizing: border-box; 
}

.nav .nav-item:hover {
  border-bottom: .3604vw solid #67514e2f;
  background: #67514e04;
}

.nav .nav-item:hover a {
  color: #67514e;
}


.nav-item a {
  width: auto;
  line-height: 2.9167vw;
  font-size: .7333vw;
  /* font-size: calc(0.625 * var(--font-size-base)); */
  font-weight: normal;
  letter-spacing: 0em;

  /* 导航栏文字 */
  color: #868686;
  transition: color .2s ease-in-out;
}

.active-navItem {
  border-bottom: .2304vw solid #67514E !important;
}

.active-navItem a {
  font-weight: bold;
}



.header-con .function-NoLogin {
  width: 4.1667vw;
  height: 2.2469vw;
  transform: translateY(.3646vw);
  /* background: #67514E; */
  padding: .1859vw .3125vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.function-NoLogin .fun-item {
  width: 1.0729vw;
  height: 1.875vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.function-NoLogin .fun-item:hover {
  background: #eee;
}


.header-con .function-Login {
  width: 10.3339vw;
  height: 2.2469vw;
  transform: translateY(.3646vw);
  /* background: #67514E; */
  padding: .1859vw .3125vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.function-Login .fun-item {
  width: 2.0729vw;
  height: 1.875vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  cursor: pointer;
}

.function-Login .fun-item:hover {
  /* background: #eee; */
}



.fun-item img {
  width: .9844vw;
  height: .9844vw;
}

.fun-item a {
  width: auto;
  height: .7292vw;
  color: #9EAAB6;
  font-family: 'AlibabaSansR';
  font-size: calc(0.525 * var(--font-size-base));
  cursor: pointer;
}

.fun-item a:visited {
  color: #9EAAB6;
}
</style>
