<template>
    <div class="clock-column">
        <!-- 显示小时、分钟、秒钟 -->
        <a href="">
            {{ hours }}:{{ minutes }}:{{ seconds }}
        </a>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            time: new Date(), // 当前时间
        };
    },
    computed: {
        hours() {
            return this.time.getHours().toString().padStart(2, "0");
        },
        minutes() {
            return this.time.getMinutes().toString().padStart(2, "0");
        },
        seconds() {
            return this.time.getSeconds().toString().padStart(2, "0");
        },
    },
    methods: {
        updateTime() {
            this.time = new Date();
        },
    },
    mounted() {
        // 每秒更新一次时间
        this.interval = setInterval(this.updateTime, 1000);
    },
    beforeDestroy() {
        // 销毁时清除定时器
        clearInterval(this.interval);
    },
};
</script>
  
<style scoped>
.clock-column {
    width: 8.75vw;
    height: 2.3438vw;
    border-radius: .5604vw;
    background: linear-gradient(135deg, #f6f6f6 0%, #e9e9e9 100%);
    box-shadow: 0vw .2083vw .4167vw rgba(159, 57, 56, 0.1);
    transition: all 0.3s ease;
    color: #333;
    position: relative;
    user-select: none !important;
}

.clock-column:hover {
    transform: translateY(-0.2604vw);
    box-shadow: 0vw .5208vw 1.0417vw rgba(156, 65, 60, 0.2), 0vw .3125vw .3125vw rgba(156, 65, 60, 0.1);
}

.clock-column a {
    width: 8.75vw;
    height: 2.3438vw;
    display: block;
    font-weight: bold;
    background: transparent;
    user-select: none;
    cursor: pointer;
    transition: color 0.3s ease, background-color 0.3s ease;
    user-select: none !important;
    border-radius: .5604vw;
    text-align: center;
    line-height: 2.3438vw;
    font-family: 'Arial', sans-serif;
    font-size: 1.2896vw;
    font-weight: bold;
    /* 添加颜色过渡效果 */
}
</style>
  