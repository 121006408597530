<template>
    <div>
        <div class="alert-overlay" @click="$emit('close')"></div>
        <div class="alert-box">
            <p>Enter some text:</p>
            <input type="text" v-model="inputText">
            <button @click="submitData">Submit</button>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "AlertBoxComponent",
    data() {
        return {
            inputText: ''
        };
    },
    methods: {
        submitData() {
            this.$emit('submit', this.inputText);
            this.$emit('close');
        }
    }
}
</script>
  
<style scoped>
.alert-box {
    position: fixed;
    top: 0;
    left: 50%;
    /* transform: translate(-50%, -50%); */
    background-color: white;
    padding: 1.0417vw;
    box-shadow: 0 .1042vw .5208vw rgba(0, 0, 0, 0.1);
    border-radius: .5604vw;
    z-index: 1000;
    width: 23.4375vw;
    height: 13.0208vw;
}

.alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    width: 100vw;
}
</style>
  